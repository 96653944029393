<template>
  <div>
    <!--      Header      -->
    <div
      class="d-flex flex-column"
      style="gap: .5rem"
    >
      <div
        class="d-flex flex-wrap align-items-start"
        style="gap: .5rem"
      >
        <h3
          class="text-primary mb-0"
          :style="!target.name ? 'opacity: .5' : ''"
        >
          {{ target.name || $t(!isTask ? 'NewProject' : 'NewTask') }}
        </h3>

        <!--      Time      -->
        <sw-date
          :date="target.endAt"
          :title="$t( !isTask ? 'ProjectCompletionDate' : 'TaskCompletionDate')"
        />
      </div>

      <div
        class="mr-auto d-flex align-items-center"
        style="gap: .5rem"
      >
        <b-badge
          v-if="isTask"
          v-b-tooltip.hover.v-primary
          :title="$t('offer.product.project')"
          variant="light-primary"
          style="font-size: .7rem"
        >
          <template v-if="target.project">
            {{ target.project.name | textIsland(34) }}
          </template>

          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </b-badge>

        <template v-if="isTask">
          <template v-if="target.projectTaskCategories && target.projectTaskCategories.length">
            <b-badge
              v-for="category in target.projectTaskCategories"
              :key="category.id"
              v-b-tooltip.hover.v-warning
              :title="$t('Category')"
              variant="light-warning"
              style="font-size: .7rem"
            >
              {{ category.name | textIsland(34) }}
            </b-badge>
          </template>

          <b-badge
            v-else
            v-b-tooltip.hover.v-warning
            :title="$t('Category')"
            variant="light-warning"
            style="font-size: .7rem"
          >
            <feather-icon
              icon="MinusIcon"
            />
          </b-badge>
        </template>
      </div>

      <div
        class="d-flex flex-wrap justify-content-between"
        style="gap: .5rem"
      >
        <div
          class="d-flex flex-wrap"
          style="gap: .5rem"
        >
          <div
            class="d-flex flex-wrap"
            style="column-gap: .5rem; row-gap: .34rem;"
          >
            <!--      Button: Return      -->
            <b-button
              size="sm"
              variant="warning"
              @click="returnBack"
            >
              <feather-icon icon="ArrowLeftIcon" />

              {{ $t('Return') }}
            </b-button>

            <!--      Button: Return to the Project      -->
            <b-button
              v-if="isTask"
              size="sm"
              variant="warning"
              @click="$router.push(`/projects/edit/${projectId}`)"
            >
              <feather-icon icon="ArrowLeftIcon" />

              {{ $t('backToProject') }}
            </b-button>

            <!--      Button: Edit      -->
            <b-button
              v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              size="sm"
              :variant="!isEdit ? 'warning' : 'success'"
              @click="isEdit = !isEdit"
            >
              {{ $t(!isEdit ? 'Edit' : 'Preview') }}
            </b-button>

            <!--      Button: Save      -->
            <b-button
              v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              size="sm"
              :variant="'success'"
              :disabled="!isValid || !isEdit"
              @click="save()"
            >
              {{ $t('Save') }}
            </b-button>

            <!--      Button: Remove      -->
            <b-button
              v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              size="sm"
              variant="danger"
              :disabled="!isEdit || !Boolean(target.id)"
              @click="removeTarget(target)"
            >
              {{ $t('Delete') }}
            </b-button>
          </div>

          <!--      Status      -->
          <span
            v-b-tooltip.hover.v-primary
            :title="$t(isEdit ? 'funnel.ChangeStatus' : !isTask ? 'ProjectStatus' : 'TaskStatus')"
          >
            <b-button
              id="pStatus"
              variant="outline-primary"
              size="sm"
              :disabled="!isEdit || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
            >
              {{ getStatusTypes().CURRENT.name || $t('ChooseStatus') }}
            </b-button>
          </span>

          <!--      Popover: Users      -->
          <b-popover
            v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
            ref="popover"
            target="pStatus"
            triggers="focus"
            placement="auto"
            variant="primary"
            :disabled="!statusList.length"
          >
            <template #title>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ $t('funnel.ChangeStatus') }}</span>
              </div>
            </template>

            <div
              v-if="statusList.length"
              class="d-flex flex-column"
            >
              <b-button
                v-for="(status, index) in statusList"
                :key="index"
                variant="flat-primary"
                class="mb-1 border-bottom"
                :disabled="status.name === getStatusTypes().CURRENT.name"
                @click="changeStatus(status)"
              >
                {{ status.name }}
              </b-button>
            </div>

            <div
              v-else
              class="text-center py-1 text-primary font-weight-bold"
            >
              <feather-icon
                size="17"
                icon="XCircleIcon"
              />

              {{ $t('errors.ITEM_NOT_FOUND') }}
            </div>
          </b-popover>

          <b-button-group>
            <!--      Button: Done Completed      -->
            <b-button
              v-b-tooltip.hover.v-success
              :variant="`${getStatusTypes().IS_COMPLETED ? '' : 'outline-'}success`"
              size="sm"
              class="btn-icon"
              :title="$t('MarkAsSuccess')"
              :disabled="!isEdit || !statusList.length || getStatusTypes().IS_COMPLETED || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              @click="doneTargetStatus(getStatusTypes().COMPLETED)"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>

            <!--      Button: Done Rejected      -->
            <b-button
              v-b-tooltip.hover.v-danger
              :variant="`${getStatusTypes().IS_REJECTED ? '' : 'outline-'}danger`"
              size="sm"
              class="btn-icon"
              :title="$t('MarkAsFailure')"
              :disabled="!isEdit || !statusList.length || getStatusTypes().IS_REJECTED || !checkRequiredPermissions([$roles.PROJECT_MODIFY])"
              @click="doneTargetStatus(getStatusTypes().REJECTED)"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-button-group>
        </div>

        <!--      Target Info      -->
        <div class="d-flex">
          <target-info
            :is-loading="isLoading"
            :infos="{
              documents: getDocumentLength(target.projectDocuments),
              completeTasks: ((target.projectTaskCompletedCount || target.projectTaskCompletedMicroTasksCount || 0) + (target.projectTaskRejected || target.projectTaskRejectedMicroTasksCount || 0)),
              tasks: target.projectTasksCount || 0,
              microTasks: (target.projectTaskMicroTasksCount || 0),
              endangeredTasks: (target.projectTaskMicroTasksCloseToDeadlineCount || 0),
              endangered: (target.projectTaskCloseToDeadlineCount || 0),
              assignedUsers: target.assignedUsers || [],
            }"
            class="mb-25"
            :is-task="isTask"
          />
        </div>
      </div>
    </div>

    <!--      Tags      -->
    <tags
      class="mt-50"
      :tags="!isTask ? target.projectTasksTags || [] : target.projectTaskTag || []"
      :project-id="projectId"
      :target-id="targetId"
      :is-loading="isLoading"
      :is-task="isTask"
      :small="false"
      :is-edit="isEdit && isTask"
      @new="addTag"
      @remove="removeTag"
    />

    <!--      Body      -->
    <div class="mt-50">
      <b-tabs>
        <!--      Task: Information      -->
        <b-tab
          :title="$t('Information')"
          active
        >
          <b-card>
            <!--      Task Preview: Options      -->
            <information-project
              v-if="!isTask"
              :is-loading="isLoading"
              :project="target"
              :is-edit="isEdit"
              :thread="thread"
              @save="({
                id,
                name,
                contactThreads,
                assignedUsers,
                startAt,
                endAt,
                description
              }) => {
                target.id = id
                target.name = name
                target.description = description
                target.contactThreads = contactThreads
                target.assignedUsers = assignedUsers
                target.startAt = startAt
                target.endAt = endAt

                isValid = Boolean(name.length)

                $forceUpdate()
              }"
            />

            <!--      Task Preview: Options      -->
            <information-task
              v-else
              :is-loading="isLoading"
              :task="target"
              :is-edit="isEdit"
              :project-id="projectId"
              @save="({
                id,
                name,
                description,
                assignedUsers,
                startAt,
                endAt,
                project,
                projectTaskCategories
              }) => {
                target.id = id
                target.name = name
                target.project = project
                target.assignedUsers = assignedUsers
                target.startAt = startAt
                target.endAt = endAt
                target.projectTaskCategories = projectTaskCategories
                target.description = description

                isValid = Boolean(name.length)

                $forceUpdate()
              }"
            />
          </b-card>
        </b-tab>

        <!--      Task: Comments      -->
        <b-tab :title="$t('Comments')">
          <b-card>
            <comments
              :is-loading="isLoading"
              :is-task="isTask"
              :is-edit="isEdit"
              :project-id="projectId"
              :target-id="targetId"
            />
          </b-card>
        </b-tab>

        <!--      Task: Documents      -->
        <b-tab :title="$t('Documents')">
          <b-card>
            <documents
              :key="`project-${!isTask ? targetId : projectId}`"
              :is-loading="isLoading"
              :project-id="!isTask ? targetId : projectId"
              :task-id="!isTask ? null : targetId"
              :is-edit="isEdit"
              :is-task="isTask"
              @remove="removeDocument"
              @update="n => {
                if (target.projectDocuments) {
                  target.projectDocuments.push(...n)

                  $forceUpdate
                }
              }"
            />
          </b-card>
        </b-tab>

        <!--      Task: Target Progress      -->
        <b-tab
          v-if="!isTask"
          :title="$t('ProjectProgress')"
        >
          <b-card>
            <!--      Target Categories Progres      -->
            <progress-project
              :is-loading="isLoading"
              :is-edit="isEdit"
              :project="target"
            />
          </b-card>
        </b-tab>

        <!--      Task: Tasks      -->
        <b-tab
          v-if="!isTask"
          :title="$t('Tasks')"
        >
          <b-card>
            <div :class="{ 'py-2': !target.id }">
              <div v-if="target.id">
                <b-button
                  v-if="isEdit && checkRequiredPermissions([$roles.PROJECT_MODIFY])"
                  variant="primary"
                  size="sm"
                  :to="{ name: 'projects/tasks/new', params: { projectId: targetId, returnToProject: true } }"
                >
                  {{ $t('Add') }}

                  <feather-icon icon="PlusIcon" />
                </b-button>

                <template v-if="target.projectTasks && target.projectTasks.length">
                  <b-card
                    v-for="task in target.projectTasks"
                    :key="task.id"
                    style="min-width: 100%;"
                    class="mb-0 rounded-0 shadow-none border-bottom"
                    no-body
                  >
                    <b-card-body class="d-flex flex-column pb-50">
                      <div
                        class="d-flex flex-wrap align-items-center justify-content-between mb-25"
                        style="gap: .34rem .5rem"
                      >
                        <div
                          class="d-flex flex-wrap justify-content-between w-100"
                          style="gap: .24rem .5rem"
                        >
                          <div
                            class="d-flex justify-content-between align-items-center"
                            style="gap: .25rem"
                          >
                            <!--      Task Title      -->
                            <router-link :to="{ path: `/projects/${target.id}/tasks/edit/${task.id}`, params: { id: task.id, returnToProject: true } }">
                              <strong
                                v-b-tooltip.hover
                                class="mb-0 cursor-pointer text-dark text-nowrap"
                                :title="task.name"
                              >
                                <template v-if="task.name.length">
                                  {{ task.name | textIsland(30, '…') }}
                                </template>

                                <feather-icon
                                  v-else
                                  icon="MinusIcon"
                                />
                              </strong>
                            </router-link>

                            <b-button
                              v-b-tooltip.hover.v-primary
                              size="sm"
                              variant="flat-primary"
                              class="p-25 text-nowrap"
                              :to="{
                                name: 'projects/tasks/micro',
                                params: {
                                  project: target.id,
                                  task: task.id
                                }
                              }"
                              :title="$t('ShowMicroTaskTasks')"
                            >
                              <feather-icon icon="CheckSquareIcon" />
                            </b-button>

                            <!--      Time      -->
                            <sw-date
                              :date="task.endAt ? task.endAt.date : ''"
                              :title="$t('TaskCompletionDate')"
                            />
                          </div>

                          <div class="d-flex">
                            <!--      Target Info      -->
                            <target-info
                              :is-loading="isLoading"
                              :infos="{
                                documents: getDocumentLength(task.projectDocuments),
                                completeTasks: ((task.projectTaskCompletedCount || task.projectTaskCompletedMicroTasksCount || 0) + (task.projectTaskRejected || task.projectTaskRejectedMicroTasksCount || 0)),
                                tasks: task.projectTasksCount || 0,
                                microTasks: (task.projectTaskMicroTasksCount || 0),
                                endangeredTasks: (task.projectTaskMicroTasksCloseToDeadlineCount || 0),
                                endangered: (task.projectTaskCloseToDeadlineCount || 0),
                                assignedUsers: task.assignedUsers || [],
                              }"
                              class="mb-25"
                              :is-task="true"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="d-flex align-items-center flex-wrap"
                        style="gap: .5rem"
                      >
                        <b-badge
                          v-b-tooltip.hover.v-primary
                          :title="$t('offer.product.project')"
                          variant="light-primary"
                          style="font-size: .7rem"
                        >
                          {{ target.name | textIsland(34) }}
                        </b-badge>

                        <template v-if="task.projectTaskCategories.length">
                          <b-badge
                            v-for="category in task.projectTaskCategories"
                            :key="category.id"
                            v-b-tooltip.hover.v-warning
                            :title="$t('Category')"
                            variant="light-warning"
                            style="font-size: .7rem"
                          >
                            {{ category.name | textIsland(34) }}
                          </b-badge>
                        </template>
                      </div>
                    </b-card-body>
                  </b-card>
                </template>

                <div
                  v-else
                  class="text-center py-1 text-primary font-weight-bold"
                >
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />

                  {{ $t('EmptyArray') }}
                </div>
              </div>

              <b-overlay
                :show="!target.id"
                no-wrap
              >
                <template #overlay>
                  <strong class="text-primary">
                    {{ $t('saveFirst') }}
                  </strong>
                </template>
              </b-overlay>
            </div>
          </b-card>
        </b-tab>

        <!--      Task: Micro Tasks      -->
        <b-tab
          v-if="isTask"
          :title="$t('MicroTaskList')"
        >
          <b-card>
            <div :class="{ 'py-2': !targetId }">
              <div v-if="targetId">
                <b-button
                  v-if="targetId && checkRequiredPermissions([$roles.PROJECT_MODIFY])"
                  variant="primary"
                  size="sm"
                  :disabled="!isEdit"
                  @click="openModalAddMicroTask()"
                >
                  {{ $t('Add') }}

                  <feather-icon icon="PlusIcon" />
                </b-button>

                <div
                  v-if="microTaskList.length"
                  class="overflow-y-scroll mt-1"
                  style="height: 480px"
                >
                  <micro-task
                    v-for="(task, i) in microTaskList"
                    :key="i"
                    :task="task"
                    :is-loading="isLoading"
                    :is-edit="isEdit"
                    @done="doneMicroTask"
                    @remove="removeMicroTask"
                  />
                </div>

                <div
                  v-else
                  class="text-center py-1 text-primary font-weight-bold"
                >
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />

                  {{ $t('EmptyArray') }}
                </div>
              </div>

              <b-overlay
                :show="!targetId"
                no-wrap
              >
                <template #overlay>
                  <strong class="text-primary">
                    {{ $t('saveFirst') }}
                  </strong>
                </template>
              </b-overlay>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>

    <div
      class="d-flex flex-wrap pb-2"
      style="column-gap: .5rem; row-gap: .34rem;"
    >
      <!--      Button: Return      -->
      <b-button
        size="sm"
        variant="warning"
        @click="returnBack"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('Return') }}
      </b-button>

      <!--      Button: Edit      -->
      <b-button
        v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
        size="sm"
        :variant="!isEdit ? 'warning' : 'success'"
        @click="isEdit = !isEdit"
      >
        {{ $t(!isEdit ? 'Edit' : 'Preview') }}
      </b-button>

      <!--      Button: Save      -->
      <b-button
        v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
        size="sm"
        :variant="'success'"
        :disabled="!isValid || !isEdit || isLoading"
        @click="save()"
      >
        {{ $t('Save') }}
      </b-button>

      <!--      Button: Remove      -->
      <b-button
        v-if="checkRequiredPermissions([$roles.PROJECT_MODIFY])"
        size="sm"
        variant="danger"
        :disabled="!isEdit || !Boolean(target.id)"
        @click="removeTarget(target)"
      >
        {{ $t('Delete') }}
      </b-button>
    </div>

    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />

    <b-modal
      v-model="mFinishTarget"
      :title="$t('Finish')"
      hide-footer
      tabindex="none"
      size="sm"
      modal-class="my-overlay sw-select"
    >
      {{ $t('notCompleteTask') }}
    </b-modal>
  </div>
</template>

<script>
import {
  BButtonGroup, BPopover, BTab, BTabs, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import SwDate from '@/pages/projects/components/new/components/Date.vue'
import Tags from '@/pages/projects/components/new/components/Tags.vue'
import InformationProject from '@/pages/projects/components/new/InformationProject.vue'
import InformationTask from '@/pages/projects/components/new/InformationTask.vue'
import Comments from '@/pages/projects/components/new/Comments.vue'
import Documents from '@/pages/projects/components/new/Documents.vue'
import ProgressProject from '@/pages/projects/components/new/ProgressProject.vue'
import TargetInfo from '@/pages/projects/components/new/components/Info.vue'
import {
  CREATE_MICRO_TASK,
  CREATE_PROJECT,
  CREATE_PROJECT_TASK,
  DELETE_PROJECT,
  DELETE_PROJECT_TASK,
  GET_MICRO_TASKS,
  GET_PROJECT,
  GET_PROJECT_STATUSES,
  GET_PROJECT_TASK,
  GET_PROJECT_TASK_STATUSES,
  GET_PROJECT_TASKS_FROM_PROJECT,
  OPEN_MODAL,
  REMOVE_MICRO_TASK,
  UPDATE_MICRO_TASK,
  UPDATE_PROJECT,
  UPDATE_PROJECT_TASK,
} from '@/@constants/mutations'
import moment from 'moment'
import { mapGetters } from 'vuex'
import MicroTask from '@/pages/projects/MicroTasks/components/Item.vue'

export default {
  components: {
    BPopover,
    BTabs,
    BTab,
    SwDate,
    Tags,
    InformationProject,
    InformationTask,
    Comments,
    Documents,
    ProgressProject,
    TargetInfo,
    MicroTask,
    BButtonGroup,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    isTask: {
      type: Boolean,
      required: false,
      default: false,
    },
    targetId: {
      type: [Number, null],
      required: false,
      default: undefined,
    },
    projectId: {
      type: [Number, null],
      required: false,
      default: undefined,
    },
    thread: {
      type: Object,
      required: false,
      default: undefined,
    },
    isReturnToProject: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isValid: false,

    mFinishTarget: false,

    isLoading: false,

    isEdit: false,
    isSaveAllowed: false,

    target: {
      id: null,
      name: '',
      assignedUsers: [],
      description: '',
      tags: [],
      comments: [],
      documents: [],
      children: [],
      projectTaskTag: [],
      startAt: null,
      endAt: null,
    },

    statusList: [],
    microTaskList: [],
  }),
  computed: {
    ...mapGetters({
      modalState: ['modal/getModalState'],
    }),
  },
  watch: {
    modalState: {
      deep: true,
      handler(n) {
        if (n.reloadContent) {
          this.ini()

          this.$nextTick(() => { this.isEdit = true })
        }
      },
    },
  },
  mounted() { this.ini() },
  methods: {
    ini() {
      this.loadStatuses()

      if (!this.isTask) {
        this.loadProject()
      } else {
        this.loadTask()
      }

      this.isEdit = this.$route.name.includes('new') || this.$route.name.includes('edit')
    },

    loadStatuses() {
      this.isLoading = true

      const type = !this.isTask ? `projectStatuses/${GET_PROJECT_STATUSES}` : `taskStatuses/${GET_PROJECT_TASK_STATUSES}`

      this.$store.dispatch(type)
        .then(res => { this.statusList = res })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    loadProject() {
      const { targetId } = this

      if (targetId) {
        this.isLoading = true

        this.$store.dispatch(`projects/${GET_PROJECT}`, targetId)
          .then(res => {
            if (!res) {
              this.$router.push({ name: !this.isTask ? 'projects' : 'projects/tasks' })

              return
            }

            this.target.id = res.id
            this.target.name = res.name
            this.target.description = res.description
            this.target.contactThreads = res.contactThreads
            this.target.assignedUsers = res.assignedUsers
            this.target.projectStatus = res.projectStatus.id
            // this.target.projectTasks = res.projectTasks
            this.target.projectTaskMicroTasksCount = res.projectTaskMicroTasksCount
            this.target.projectDocuments = res.projectDocuments
            this.target.projectCategoryCompletedTaskCount = res.projectCategoryCompletedTaskCount
            this.target.projectTaskMicroTasksCloseToDeadlineCount = res.projectTaskMicroTasksCloseToDeadlineCount
            this.target.projectTasksCount = res.projectTasksCount
            this.target.projectTaskCompletedCount = res.projectTaskCompletedCount
            this.target.projectTaskRejected = res.projectTaskRejected
            this.target.projectTaskCloseToDeadlineCount = res.projectTaskCloseToDeadlineCount
            this.target.projectTasksTags = res.projectTasksTags
            this.target.startAt = res.startAt?.date || ''
            this.target.endAt = res.endAt?.date || ''

            this.loadProjectTask(targetId)

            this.isValid = true
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

            this.$router.push({ name: !this.isTask ? 'projects' : 'projects/tasks' })
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.isLoading = false
      }
    },

    loadProjectTask(projectId) {
      this.isLoading = true

      this.$store.dispatch(`tasks/${GET_PROJECT_TASKS_FROM_PROJECT}`, projectId)
        .then(res => {
          this.target.projectTasks = res
          this.$forceUpdate()
        })
        .finally(() => { this.isLoading = false })
    },

    loadTask() {
      const { targetId, projectId } = this

      if (targetId && projectId) {
        this.isLoading = true

        this.$store.dispatch(`tasks/${GET_PROJECT_TASK}`, { project: projectId, id: targetId })
          .then(res => {
            if (!res) {
              this.$router.push({ name: !this.isTask ? 'projects' : 'projects/tasks' })

              return
            }

            this.target.id = res.id
            this.target.name = res.name
            this.target.description = res.description
            this.target.project = res.project
            this.target.assignedUsers = res.assignedUsers
            this.target.projectTaskCategories = res.projectTaskCategories
            this.target.projectTaskStatus = res.projectTaskStatus.id
            this.target.projectTaskMicroTasks = res.projectTaskMicroTasks
            this.target.projectTaskMicroTasksCount = res.projectTaskMicroTasksCount
            this.target.projectDocuments = res.projectDocuments
            this.target.projectTaskCompletedMicroTasksCount = res.projectTaskCompletedMicroTasksCount
            this.target.projectTaskRejectedMicroTasksCount = res.projectTaskRejectedMicroTasksCount
            this.target.projectTaskTag = res.projectTaskTag
            this.target.categories = res.categories
            this.target.startAt = res.startAt?.date || ''
            this.target.endAt = res.endAt?.date || ''

            this.isValid = true

            const filters = { project: projectId, task: res.id }

            this.loadMicroTasks(filters)
          })

          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

            this.$router.push({ name: !this.isTask ? 'projects' : 'projects/tasks' })
          })

          .finally(() => { this.isLoading = false })
      } else {
        this.isLoading = false
      }
    },

    doneTargetStatus(status) {
      if (this.target[!this.isTask ? 'projectStatus' : 'projectTaskStatus'] !== status.id) {
        this.showAlert(
          'warning',
          this.$i18n.t(!this.isTask ? 'FinishTheProject' : 'FinishTheTask'),
          status === this.getStatusTypes().COMPLETED
            ? this.$i18n.t(!this.isTask ? 'notCompleteTask' : 'notCompleteMicroTask')
            : this.$i18n.t(!this.isTask ? 'notCompleteTaskBad' : 'notCompleteMicroTaskBad'),
          false,
          this.$i18n.t('Yes'),
          'sd',
          this.$i18n.t('NoNo'),
        )
          .then(res => {
            this.target[!this.isTask ? 'projectStatus' : 'projectTaskStatus'] = status.id

            this.$forceUpdate()

            this.saveStatus(res.isConfirmed)
          })
      }
    },

    changeStatus(status) {
      if (this.target[!this.isTask ? 'projectStatus' : 'projectTaskStatus'] !== status.id) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
        // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.target[!this.isTask ? 'projectStatus' : 'projectTaskStatus'] = status.id
            // this.target[!this.isTask ? 'forceCloseAssignedTasks' : 'forceCloseAssignedMicroTask'] = true

            this.$forceUpdate()

            this.saveStatus(false)
          })
      }
    },

    saveStatus(isMassClosing) {
      if (this.target?.id) {
        const payload = this.getStatusPayload(isMassClosing)

        if (payload) this.sendStatus(payload)
      }
    },

    getStatusPayload(isMassClosing) {
      const { projectStatus, projectTaskStatus } = this.target
      const { isTask } = this

      if (projectStatus || projectTaskStatus) {
        const payload = {
          id: this.target.id,
        }

        if (!isTask) {
          payload.projectStatus = projectStatus
          payload.forceCloseAssignedTasks = false
          payload.forceCloseAssignedMicroTask = false
        } else {
          payload.project = this.target.project.id
          payload.projectTaskStatus = projectTaskStatus
          payload.forceCloseAssignedMicroTask = false
        }

        if (isMassClosing) {
          if (!isTask) {
            payload.forceCloseAssignedTasks = true
            payload.forceCloseAssignedMicroTask = true
          } else {
            payload.forceCloseAssignedMicroTask = true
          }
        }

        return payload
      }

      return null
    },

    sendStatus(payload) {
      const type = !this.isTask ? `projects/${UPDATE_PROJECT}` : `tasks/${UPDATE_PROJECT_TASK}`

      this.$store.dispatch(type, payload)
        .then(() => {
          if (!this.isTask) {
            this.showToast('success', this.$i18n.t('ProjectUpdated'))
          } else {
            this.showToast('success', this.$i18n.t('TaskUpdated'))
          }
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    openModalAddMicroTask() {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addMicroTaskModal',
          size: 'md',
          modalTitle: this.$i18n.t('MicroTask'),
          okButtonTitle: '',
          data: {
            task: this.target,
          },
        })
    },

    save() {
      this.isEdit = false

      if (this.isValid) {
        const payload = this.getPayload(this.target)

        if (payload) {
          this.send(payload)
        } else {
          this.isEdit = true
        }
      } else {
        this.isEdit = true
      }
    },

    getPayload() {
      const {
        id,
        name,
        assignedUsers,
        startAt,
        endAt,
        description,
        projectStatus,
        projectTaskStatus,
        projectTaskTag,
      } = this.target

      const {
        isTask,
        targetId,
        projectId,
      } = this

      if (projectStatus || projectTaskStatus) {
        const payload = {
          name,
          assignedUsers: assignedUsers.map(user => user.id),
          startAt,
          endAt,
          description,
        }

        if (id) {
          payload.id = id
        } else {
          if (isTask && targetId) payload.id = String(targetId)

          if (!isTask && projectId) payload.id = String(projectId)
        }

        if (!isTask) {
          payload.projectStatus = projectStatus
          payload.contactThreads = this.target.contactThreads.map(contact => contact.id)
        } else {
          payload.projectTaskStatus = projectTaskStatus
          payload.project = this.target.project?.id || this.target.project
          payload.projectTaskCategories = this.target.projectTaskCategories.map(t => t.id)
          payload.projectTaskTag = projectTaskTag.map(tag => tag.id)
        }

        return payload
      }

      this.showToast('warning', this.$i18n.t('PleaseSelectStatusToContinue'))

      return null
    },

    send(payload) {
      let type

      if (!this.isTask) {
        type = payload?.id ? `projects/${UPDATE_PROJECT}` : `projects/${CREATE_PROJECT}`
      } else {
        type = payload?.id ? `tasks/${UPDATE_PROJECT_TASK}` : `tasks/${CREATE_PROJECT_TASK}`
      }

      this.$store.dispatch(type, payload)
        .then(res => {
          if (!this.isTask) {
            this.showToast('success', this.$i18n.t(payload?.id ? 'ProjectUpdated' : 'ProjectAdded'))
          } else {
            this.showToast('success', this.$i18n.t(payload?.id ? 'TaskUpdated' : 'TaskAdded'))
          }

          if (!payload.id) {
            const path = !this.isTask ? `/projects/edit/${res}` : `/projects/${payload.project}/tasks/edit/${res}`

            this.$router.push({ path, params: res })
          }

          this.target.id = res

          this.$forceUpdate()
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false; this.isEdit = true })
    },

    getStatusTypes() {
      const { target, statusList } = this

      const empty = {
        id: null,
        name: '',
      }

      const CURRENT = statusList.find(el => el.id === (target.projectStatus || target.projectTaskStatus)) || empty
      const COMPLETED = statusList.find(el => el.type === 'COMPLETED') || empty
      const REJECTED = statusList.find(el => el.type === 'REJECTED') || empty

      const IS_COMPLETED = Boolean(CURRENT === COMPLETED && statusList.length)
      const IS_REJECTED = Boolean(CURRENT === REJECTED && statusList.length)

      return {
        CURRENT,
        COMPLETED,
        REJECTED,
        IS_COMPLETED,
        IS_REJECTED,
      }
    },

    isAllDay(start, end) {
      return end.diff(start, 'days') === 1
    },

    getEndangered(list) {
      if (!list || !list.length) return ''

      return list.filter(item => (item.endAt ? moment(item.endAt.date, 'DD.MM.YYYY hh:mm:ss').isSame(moment(), 'day') : ''))
    },

    addTag(tag) {
      if (this.isTask) {
        this.target.projectTaskTag.push(tag)

        this.saveTag()
      }
    },

    removeTag(id) {
      if (this.isTask) {
        this.target.projectTaskTag.splice(this.target.projectTaskTag.findIndex(tag => tag.id === id), 1)

        this.saveTag()
      }
    },

    saveTag() {
      const { id, project, projectTaskTag } = this.target

      if (id) {
        const payload = {
          id,
          project: project?.id || project,
          projectTaskTag: projectTaskTag.map(t => t.id),
        }

        if (payload) this.send(payload)
      }
    },

    removeTarget(target) {
      if (target && target.id) {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => {
            const { isTask } = this

            const type = !isTask ? `projects/${DELETE_PROJECT}` : `tasks/${DELETE_PROJECT_TASK}`
            const payload = !isTask ? target.id : {
              id: target.id,
              project: target.project.id,
            }

            this.$store.dispatch(type, payload)
              .then(() => this.$router.push({ name: 'projects' }))

              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })

              .finally(() => {
                this.isLoading = false
              })
          })
      }
    },

    returnBack() {
      this.$router.go(-1)
      // const { isTask, projectId, isReturnToProject } = this
      //
      // let path = !isTask ? '/projects' : '/projects/tasks'
      //
      // if (isReturnToProject) path = `/projects/edit/${projectId}`
      //
      // this.$router.push({ path, params: { projectId: isReturnToProject ? projectId : null } })
    },

    getDocumentLength(list) {
      if (!list?.length) return 0

      return list.map(doc => doc.files.length).reduce((a, b) => a + b)
    },

    loadMicroTasks(filters) {
      this.isLoading = true

      this.$store.dispatch(`microTasks/${GET_MICRO_TASKS}`, filters)
        .then(res => {
          this.microTaskList = res

          if (this.taskList) this.taskList.sort((a, b) => (b.doneType === null) - (a.doneType === null) || a.name - b.name)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    doneMicroTask({ task, type }) {
      const payload = {
        id: task.id,
        project: task.projectTask.project.id,
        projectTask: task.projectTask.id,
        doneType: type,
        doneAt: moment(new Date()).format('DD.MM.YYYY hh:mm:ss'),
        doneBy: this.$store.getters['auth/getCurrentUser'].id,
      }

      if (type === null) {
        payload.doneAt = null
        payload.doneBy = null
      }

      if (task.doneType !== payload.doneType) this.sendMicroTask(payload)
    },

    sendMicroTask(payload) {
      this.isLoading = true

      const action = payload?.id ? UPDATE_MICRO_TASK : CREATE_MICRO_TASK

      this.$store.dispatch(`microTasks/${action}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t(payload?.id ? 'MicroTaskUpdated' : 'MicroTaskAdded'))

          if (payload.id) {
            const item = this.microTaskList.find(task => task.id === payload.id)

            item.doneType = payload.doneType
            item.doneBy = payload.doneBy?.name ? payload.doneBy : this.$store.getters['auth/getCurrentUser']
            item.doneAt = { date: payload.doneAt }

            this.target.projectTaskCompletedMicroTasksCount += payload.doneType ? 1 : -1
          }

          this.microTaskList.sort((a, b) => (b.doneType === null) - (a.doneType === null) || a.name - b.name)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

        .finally(() => { this.isLoading = false })
    },

    removeMicroTask(taskToDelete) {
      const { id } = taskToDelete

      if (id && this.isTask) {
        this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(() => {
            this.isLoading = true

            const payload = {
              id,
              project: this.projectId,
              projectTask: this.targetId,
            }

            this.$store.dispatch(`microTasks/${REMOVE_MICRO_TASK}`, payload)
              .then(() => {
                this.microTaskList.splice(this.microTaskList.findIndex(task => task.id === id), 1)
              })

              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })

              .finally(() => {
                this.isLoading = false
              })
          })
      }
    },

    removeDocument(id) {
      if (this.target.projectDocuments) {
        this.target.projectDocuments.splice(this.target.projectDocuments.findIndex(p => p.id === id), 1)

        this.$forceUpdate()
      }
    },
  },
}
</script>
